/*global axios */
class CarComponentB2C extends CarComponentB2C {

    async addGiftcard() {
        let res = await axios.get('/ecommerce/api/checkCoupon/' + this.giftCardCode);
        if (res.data.success) {
            this.orderCampaign = this.giftCardCode;
            await this.discountThrottled();
        } else {
            res = await axios.post('/ecommerce/api/giftcard', {'barCode': this.giftCardCode, 'cust': this.$store.state.customer});
            this.$store.dispatch('ItemGiftcardAddToCar', this.giftCardCode);
        }
    }

}

CarComponentB2C.registerComponent();

class CarComponentB2COfisnack extends CarComponentB2C {

    goToShop() {
        let storeCategory = this.$store.getters.getCategoryDataByCode("2110");
        let catRoutePath = (`${storeCategory.code}__${storeCategory.name}`);
        this.$router.push({
            name: 'CategorySEO',
            params: {category: this.$route.params.category},
            query: {category: catRoutePath},
        });
    }

    getTemplate() {
        return `<div id="carModal" class="row cart-items" v-if="!viewIsLoading">
                  <h5 class="col-12 title">{{tr("Your Purchase")}}</h5>
                  <div class="col-12">
                     <addSOFavModalComponent :from="'car-items'"></addSOFavModalComponent>
                     <template v-if="detailItem">
                        <carModalDetailComponent :item="detailItem" :closeCallback="closeDetail" ></carModalDetailComponent>
                    </template>
                    <template v-if="errors.length>0">
                        <div class="alert alert-warning alert-dismissible fade show" role="alert">
                            <template v-for="error of errors">
                                <strong>{{tr(error)}}</strong>
                            </template>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </template>
                     <div class="col-12 px-0 px-sm-3">
                          <div class="stepwizard">
                              <div class="stepwizard-row setup-panel">
                                  <div class="stepwizard-step">
                                      <button @click="navListItems(1)" :class="{'btn': true, 'active': currentStep==1 , 'disabled': currentStep<1}" :disabled="currentStep<1">1</button>
                                      <p>{{tr("Items in Cart")}}</p>
                                  </div>
                                  <div class="stepwizard-step">
                                      <button @click="navListItems(2)" :class="{'btn': true, 'active': currentStep==2 , 'disabled': currentStep<2}" :disabled="currentStep<2">2</button>
                                      <p>{{tr("Delivery Data")}}</p>
                                  </div>
                                  <div class="stepwizard-step">
                                      <button  @click="navListItems(3)" :class="{'btn': true, 'active': currentStep==3 , 'disabled': currentStep<3}" :disabled="currentStep>3">3</button>
                                      <p>{{tr('Payment')}}</p>
                                  </div>
                              </div>
                          </div>
                          <div class="stepwizard-content row">
                             <div class="col-12 setup-content order-items" id="step-1" v-if="currentStep==1">
                                 <div class="table-responsive">
                                    <div v-if="$store.getters.useDiscountCoupon || $store.getters.useGiftcard" class="col-12 px-1 px-md-3">
                                        <div class="bordered my-2">
                                            <div class="form-row px-2 py-2">
                                                <div class="col-12 col-md-6 px-3" :class="{'mb-4 mb-md-0': $store.getters.useGiftcard}" v-if="$store.getters.useDiscountCoupon">
                                                    <h6 class="col-12 mb-3 px-0 align-items-center d-flex"><i class="delivery-icon fas fa-tag fa-2x mr-3" style="font-size: 1.6em;"></i>{{tr('Discount / Giftcard')}}</h6>
                                                    <div class="form-row" v-if="$store.getters.useDiscountCoupon && $store.getters.useGiftcard">
                                                        <input class="col-8 form-control" :placeholder="tr('Enter here your discount code / Giftcard code')" v-model="giftCardCode" >
                                                        <button type="button" class="col ml-2 btn btn-custom" @click="addGiftcard">{{tr("Apply")}}</button>
                                                    </div>
                                                    <div class="form-row" v-else-if="$store.getters.useGiftcard">
                                                        <input class="col-8 form-control" :placeholder="tr('Enter here your Giftcard code')" v-model="giftCardCode">
                                                        <button type="button" class="col ml-2 btn btn-custom btn btn-custom" @click="addGiftcard">{{tr("Apply")}}</button>
                                                    </div>
                                                    <div class="form-row" v-else-if="$store.getters.useDiscountCoupon">
                                                        <input class="col-8 form-control" :placeholder="tr('Enter here your discount code')" v-model="orderCampaign" >
                                                        <button type="button" class="col ml-2 btn btn-custom" @click="calculateDiscount">{{tr("Apply")}}</button>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-12 col-md-6 px-3" v-if="$store.getters.useGiftcard">
                                                    <h6 class="col-12 mb-3 px-0 align-items-center d-flex"><i class="delivery-icon fas fa-gift fa-2x mr-3" style="font-size: 1.6em;"></i>{{tr('Giftcard')}}</h6>
                                                    <div class="form-row">
                                                        <input class="col-8 form-control" :placeholder="tr('Enter here your Giftcard code')" v-model="giftCardCode">
                                                        <button type="button" class="col ml-2 btn btn-custom btn btn-custom" @click="addGiftcard">{{tr("Apply")}}</button>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                     <table class="table table-striped mb-0">
                                         <thead>
                                             <tr>
                                                 <th scope="col" class="center">{{tr("Item")}}</th>
                                                 <th scope="col" class="d-none d-sm-block">{{tr("Unit")}}</th>
                                                 <th scope="col" class="text-right">{{tr("Price")}}</th>
                                                 <th scope="col" colspan="3" class="text-center">{{tr("Qty")}}</th>
                                                 <th scope="col" class="text-right">{{tr("Total")}}</th>
                                                 <th></th>
                                             </tr>
                                         </thead>
                                         <tbody v-if="orderTemplate">
                                            <template v-for="item in getOrderItems">
                                             <template v-if="item.message">
                                               <tr :key="'carItem-'+item.fields.ArtCode" :title="item.message" :class="{ 'text-danger': item.message }" >
                                                   <td colspan="4">
                                                      {{item.fields.Name}}
                                                      <!--<a href="#" class="item-link" :class="{ 'text-danger': item.message }" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}} </a>-->
                                                   </td>
                                                   <td colspan="3">
                                                      {{tr(item.message)}}
                                                   </td>
                                                    <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                      <span class="row"  :title="tr('Delete Element from Car')">
                                                          <i class="fas fa-trash"></i>
                                                      </span>
                                                   </td>
                                                </tr>
                                             </template>
                                             <template v-else>
                                                 <tr :key="'carItem-'+item.fields.ArtCode">
                                                   <td>
                                                      {{item.fields.Name}}
                                                      <!--<a href="#" class="item-link" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}}</a>-->
                                                   </td>
                                                   <td scope="row" class="d-none d-sm-block">{{item.fields.Unit}}</td>
                                                   <td scope="row" class="text-right" >
                                                       <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                           <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                {{number_format(item.fields.VATPrice,2)}}
                                                           </template>
                                                           <template v-else>
                                                                {{number_format(item.fields.Price,2)}}
                                                            </template>
                                                       </template>
                                                       <template v-else>
                                                           --
                                                       </template>
                                                   </td>
                                                   <td scope="row" colspan="3" class="center" >
                                                      <div class="form-inline row  justify-content-center">
                                                         <template v-if="$store.getters.getGiftCardItem && $store.getters.getGiftCardItem.fields.Code == item.fields.ArtCode">
                                                                    <span>{{parseInt(item.fields.Qty)}}</span>
                                                         </template>
                                                         <template v-else>
                                                             <label class="sr-only form-control" @click="()=>itemQtyIncrement(item.fields.ArtCode)">
                                                                  <i class="fas fa-plus-circle waves-effect"></i>
                                                              </label>
                                                              <input :key="'carItemInput-'+item.fields.ArtCode" type="number" :id="item.fields.ArtCode" class="form-control col-8" min="1"  :max="getMaxStockByCode(item.fields.ArtCode)" :value="parseInt(item.fields.Qty)" @change="updateCarQty" @onfocus="autoResetBlur" @focusout="updateCarQty">
                                                              <label class="sr-only form-control" @click="()=>itemQtyDecrement(item.fields.ArtCode)" >
                                                                  <i class="fas fa-minus-circle waves-effect"></i>
                                                              </label>
                                                          </template>
                                                       </div>
                                                   </td>
                                                   <td class="text-right">
                                                       <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                           <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                {{number_format(item.fields.RowTotal,2)}}
                                                           </template>
                                                           <template v-else>
                                                                {{number_format(item.fields.RowNet,2)}}
                                                            </template>
                                                       </template>
                                                       <template v-else>
                                                           --
                                                       </template>
                                                   </td>
                                                   <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                      <span class="delet-icon"  :title="tr('Delete Element from Car')">
                                                          <i class="fas fa-trash"></i>
                                                      </span>
                                                   </td>
                                                 </tr>
                                             </template>
                                            </template>
                                            <tr v-if="getDiscountCoupon">
                                                <td colspan="8" class="text-left text-md-right">
                                                  <template >
                                                    <strong>{{tr("Discount")}}: $ {{getDiscountCoupon}} </strong>
                                                  </template>
                                                </td>
                                            </tr>
                                             <tr v-if="getShipCost">
                                                <td colspan="8" class="text-left text-md-right">
                                                  <template>
                                                    <strong>{{tr("Ship Cost")}}:$ {{getShipCost}}</strong> 
                                                  </template>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="8" class="text-left text-md-right">
                                                    <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                        <strong class="mr-2">Total</strong>
                                                   </template>
                                                   <template v-else>
                                                        <strong class="mr-2">Total (IVA EXC)</strong>
                                                    </template>
                                                    <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                       <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                            <strong>$ {{number_format(orderTemplate.fields.Total,2)}}</strong>
                                                       </template>
                                                       <template v-else>
                                                            <strong>$ {{number_format(orderTemplate.fields.SubTotal,2)}}</strong>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        --
                                                    </template>
                                                </td>
                                            </tr>
                                         </tbody>
                                     </table>
                                     <div class="payment-message text-left text-md-right mb-3 px-md-3">Todos los precios tienen IVA incluído</div>
                                 </div>
                                 <div class="row mt-2">
                                      <div class="col-12 col-md-6 text-center text-md-left">
                                          <button key="btn-1-step-1" @click="goToShop()" class="btn btn-secondary mb-2 continue-shopping">
                                                <span class="icon"><i class="fa-icon fas fa-cart-plus mr-1"></i></span>
                                                {{tr('Continue Shopping')}}
                                          </button>
                                          <button key="btn-2-step-1" @click="clearCar()" class="btn btn-secondary mb-2 clear">
                                              <i class="fas fa-trash mr-1"></i>
                                              {{tr('Clear')}}
                                          </button>
                                      </div>
                                      <div class="col-12 col-md-6 order-first order-md-last text-center text-md-right">
                                      <button key="brn-3-step-1" :disabled="itemsSelected.length==0" v-bind:class="{ disabled:itemsSelected.length==0}" class="btn btn-secondary mb-2 add-to-favorite-orders" data-toggle="modal" data-target="#addSOFavModal">
                                            <span class="icon"><i class="fa-icon fas fa-star mr-1"></i></span>
                                            {{tr('Add To Favorite Orders')}}
                                      </button>
                                          <button key="brn-4-step-1" :disabled="itemsSelected.length==0" v-bind:class="{ disabled:itemsSelected.length==0}" class="btn btn-success mb-2 nextBtn delivery-data" type="button" @click="nextStep($event)">
                                              <span>{{tr('Delivery Data')}}</span>
                                              <span class="icon"><i class="fa-icon fas fa-arrow-right"></i></span>
                                          </button>
                                      </div>
                                 </div>
                             </div>
                             <div class="col-12 setup-content" id="step-2" v-if="currentStep==2">
                                <div class="row">
                                    <div class="col-12 col-md-7">
                                          <div class="row" >
                                            <carDeliveryList @deliveryselected="calculateOrderTotals()" />
                                          </div>
                                          <div class="col-12">
                                        <div class="form-row m-0 mb-3 px-2 bordered">
                                           <h6 class="col-12 align-items-center d-flex"><i class="delivery-icon fas fa-credit-card fa-2x mr-3" style="font-size: 1.6em;"></i>{{tr('Seleccione un método de pago')}}</h6>
                                           <div class="form-group col-12 col-md-6 px-2">
                                                <label class="col-form-label">{{tr("PayTerm")}}</label>
                                                <select  class="form-control" @change="updateSelectValues('PayTerm')">
                                                     <template v-for="payterm of payTerms">
                                                        <template v-if="$store.state.setting.AllowNotOnlyPayments">
                                                            <option :value="payterm.fields.PayTermCode" :selected="payterm.fields.RequireOnlinePayment">{{payterm.fields.PayTermName}}</option>
                                                        </template>
                                                        <template v-else>
                                                            <template v-if="payterm.fields.RequireOnlinePayment">
                                                                <option :value="payterm.fields.PayTermCode" :selected="payterm.fields.RequireOnlinePayment">{{payterm.fields.PayTermName}}</option>
                                                            </template>
                                                        </template>
                                                    </template>
                                                </select>
                                           </div>
                                           <div class="col-12 payment-message px-2" v-if="$store.state.setting.AllowNotOnlyPayments!=true">
                                              <p class="mb-1">Sólo pago con tarjetas</p>
                                           </div>
                                       </div>
                                  </div>
                                </div>
                                    <div class="col-12 col-md-5">
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                 <thead>
                                                     <tr>   
                                                         <th scope="col">{{tr("Item")}}</th>
                                                         <th scope="col" class="text-right">{{tr("Price")}} {{showPriceLabel()}}</th>
                                                         <th scope="col" colspan="3" class="text-center">{{tr("Qty")}}</th>
                                                         <th scope="col" class="text-right">{{tr("Total")}} {{showPriceLabel()}}</th>
                                                         <th></th>
                                                     </tr>
                                                 </thead>
                                                 <tbody v-if="orderTemplate">
                                                    <template v-for="item in getOrderItems">
                                                     <template v-if="item.message">
                                                       <tr :key="'carItem-'+item.fields.ArtCode" :title="item.message" :class="{ 'text-danger': item.message }" >   
                                                           <td colspan="4">
                                                              <a href="#" class="item-link" :class="{ 'text-danger': item.message }" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}} </a>
                                                           </td>
                                                           <td colspan="3">
                                                              {{tr(item.message)}}
                                                           </td>
                                                            <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                              <span class="row"  :title="tr('Delete Element from Car')">
                                                                  <i class="fas fa-trash"></i>
                                                              </span>
                                                           </td>
                                                        </tr>
                                                     </template>
                                                     <template v-else>
                                                         <tr :key="'carItem-'+item.fields.ArtCode">
                                                          
                                                           <td @click="viewDetailItem(item)">
                                                              <a href="#" class="item-link" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}}</a>
                                                           </td>
                                                          
                                                           <td scope="row" class="text-right" >
                                                               <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                                   <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                        {{number_format(item.fields.VATPrice,2)}}
                                                                   </template>
                                                                   <template v-else>
                                                                        {{number_format(item.fields.Price,2)}}
                                                                    </template>
                                                               </template>
                                                               <template v-else>
                                                                   --
                                                               </template>
                                                           </td>
                                                           <td scope="row" colspan="3" class="center" >
                                                              <div class="form-inline row  justify-content-center">
                                                                 <template v-if="$store.getters.getGiftCardItem && $store.getters.getGiftCardItem.fields.Code == item.fields.ArtCode">
                                                                    <span>{{parseInt(item.fields.Qty)}}</span>
                                                                 </template>
                                                                 <template v-else>
                                                                      <label class="sr-only form-control" @click="()=>itemQtyIncrement(item.fields.ArtCode)">
                                                                          <i class="fas fa-plus-circle waves-effect"></i>
                                                                      </label>
                                                                      <input :key="'carItemInput-'+item.fields.ArtCode" type="number" :id="item.fields.ArtCode" class="form-control col-8" min="1"  :max="getMaxStockByCode(item.fields.ArtCode)" :value="parseInt(item.fields.Qty)" @change="updateCarQty">
                                                                      <label class="sr-only form-control"@click="()=>itemQtyDecrement(item.fields.ArtCode)" >
                                                                          <i class="fas fa-minus-circle waves-effect"></i>
                                                                      </label>
                                                                  </template>
                                                               </div>
                                                           </td>
                                                           <td class="text-right">
                                                               <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                                   <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                        {{number_format(item.fields.RowTotal,2)}}
                                                                   </template>
                                                                   <template v-else>
                                                                        {{number_format(item.fields.RowNet,2)}}
                                                                    </template>
                                                               </template>
                                                               <template v-else>
                                                                   --
                                                               </template>
                                                           </td>
                                                           <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                              <span class="delet-icon"  :title="tr('Delete Element from Car')">
                                                                  <i class="fas fa-trash"></i>
                                                              </span>
                                                           </td>
                                                         </tr>
                                                     </template>
                                                    </template>
                                                    <tr v-if="getDiscountCoupon">
                                                        <td colspan="7" class="text-left text-md-right">
                                                          <template >
                                                            <strong>{{tr("Discount")}}: $ {{getDiscountCoupon}} </strong>
                                                          </template>
                                                        </td>
                                                    </tr>
                                                     <tr v-if="getShipCost!=null">
                                                        <td colspan="7" class="text-left text-md-right">
                                                          <template>
                                                            <strong>{{tr("Ship Cost")}}: $ {{getShipCost}}</strong> 
                                                          </template>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="7" class="text-left text-md-right">
                                                            <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                <strong class="mr-2">Total (IVA INC)</strong>
                                                           </template>
                                                           <template v-else>
                                                                <strong class="mr-2">Total (IVA EXC)</strong>
                                                            </template>
                                                            <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                               <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                    <strong>$ {{number_format(orderTemplate.fields.Total,2)}}</strong>
                                                               </template>
                                                               <template v-else>
                                                                    <strong>$ {{number_format(orderTemplate.fields.SubTotal,2)}}</strong>
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                --
                                                            </template>
                                                        </td>
                                                    </tr>
                                                 </tbody>
                                             </table>
                                        </div>
                                        <div class="text-right align-self-end">
                                            <button key="btn-2-step-2" @click="payAndClose($event)" v-bind:class="{'btn btn-success pay':true, disabled:itemsSelected.length==0}">{{tr('Pagar')}}</button>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button key="btn-1-step-2" class="btn btn-secondary prevBtn mb-2 back-to-items-in-cart" type="button" @click="prevStep()">
                                            <span class="icon"><i class="fa-icon fas fa-arrow-left"></i></span>
                                            <span>{{tr('Back to Items in Cart')}}</span>
                                        </button>
                                  </div>
                                </div>
                             </div>
                             <div class="col-12 setup-content my-4" id="step-3" v-if="currentStep==3">
                                     <div class="col-12">
                                         <template v-if="status=='loading'">
                                             <div class="">
                                                 <h5 class="text-center">{{tr("Your Order is processing")}}.{{tr("Please Wait")}}.</h5>
                                             </div>
                                         </template>
                                         <template v-if="status=='failure'">
                                             <h5 class="text-center">{{tr("The Sales Order can't be created")}}.</h5>
                                             <div v-if="result.error.length > 0" class="col-12 col-md-6 offset-md-3 error-message px-1">
                                                 <p v-for="error of result.error" class="text-center">{{tr(error)}}</p>
                                             </div>
                                             <button class="btn btn-secondary prevBtn float-left back-to-items-in-cart" type="button" @click="currentStep=1">
                                                    <span class="icon"><i class="fa-icon fas fa-arrow-left"></i></span>
                                                    <span>{{tr('Back to Items in Cart')}}</span>
                                             </button>
                                         </template>
                                         <template  v-if="status=='result' || status=='completed'">
                                             <h5 class="text-center">{{ tr([successMessage,result.so.fields.SerNr]) }}.</h5>
                                             <div v-if="result.error.length > 0" class="col-12 col-md-6 offset-md-3 error-message my-3 px-1 pt-2 d-none">
                                                 <h5 class="text-center">{{tr("Information")}}:</h5>
                                                 <p v-for="error of result.error" class="text-center">
                                                    {{tr(error)}}
                                                </p>
                                             </div>
                                             <template v-if="mpAvailable && mpdata && mpdata.amount">
                                                <div class="col-12 col-md-6 offset-md-3 alert alert-warning px-1" role="alert" v-if="paymentMsg">
                                                    <template v-if="Array.isArray(paymentMsg)">
                                                         <p v-for="error of paymentMsg" class="text-center array">{{tr(error.description.charAt(0).toUpperCase() + error.description.slice(1))}}</p>
                                                    </template>
                                                    <template v-else>
                                                        <p class="text-center single">{{tr(paymentMsg.charAt(0).toUpperCase() + paymentMsg.slice(1))}}</p>
                                                    </template>
                                                </div>
                                                <template v-if="paymentIsProcess">
                                                    <div class="col-12 col-md-6 offset-md-3 alert alert-success text-center" role="alert">
                                                        <icon name="regular/check-circle" scale="5" class="col-6"/>
                                                        <h4 class="col-12 text-center mt-2">{{tr("The payment was approved successfully")}}</h4>
                                                        <strong class="d-block mt-2">{{tr('It may take a few minutes to be reflected')}}</strong>
                                                    </div>
                                                </template>
                                                <div class="row justify-content-center mt-4"  v-if="doOnlinePayment">
                                                    <template v-if="$store.getters.getSetting.MercadoPagoMode==1">
                                                        <mercadopagoFormCustom :info="mpdata" @payment-init="paymentInit" @payment-result="paymentProcess"></mercadopagoFormCustom>
                                                    </template>
                                                    <template v-else>
                                                        <mercadopagoCustom :info="mpdata" @payment-init="paymentInit" @payment-result="paymentProcess"></mercadopagoCustom>
                                                    </template>
                                                </div>
                                             </template>
                                             <div class="row justify-content-center mt-4">
                                               <router-link key="btn-1-step-3" to="/profile" class="btn btn-custom">{{tr('View Shopping List')}}</router-link>
                                             </div>
                                         </template>
                                     </div>
                             </div>
                          </div>
                      </div>
                  </div>
              </div>`;
    }

}

CarComponentB2COfisnack.registerComponent();
